import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

interface myData {
  success: boolean;
  msg: string;
  result: string;
}

@Injectable()
export class AuthService {
  webapiurl: string = "https://giveglitz.com/webservices_giveglits";
  // private loggedInStatus = JSON.parse(localStorage.getItem('LoggedIn')||'false')
  constructor(private http: HttpClient) {}

  put_student_data(body) {
    return this.http.post<myData>(this.webapiurl + "/api/auth.php", body);
  }

  add_user(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_user.php",
      body
    );
  }

  getUsers(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_users_data.php",
      body
    );
  }

  getRooms(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_rooms_data.php",
      body
    );
  }
  add_room(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_room.php",
      body
    );
  }

  getKey(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(this.webapiurl + "/api/random.php", body);
  }

  add_device(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  getCustomerData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_user_list.php",
      body
    );
  }

  getCartManagerData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_cart_master.php",
      body
    );
  }

  get_seq_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_seq_data.php",
      body
    );
  }

  getCartViewData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  getcartData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_cart_data.php",
      body
    );
  }

  get_fbar_list(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_offer_bar.php",
      body
    );
  }

  getcartupdatetime(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_cart_update_time.php",
      body
    );
  }

  getCompletedOrderData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  getNewOrderData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  getOrderViewData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  getUnderProcessedOrderData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  add_vendor(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_vendor.php",
      body
    );
  }

  add_cat(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_cat.php",
      body
    );
  }

  update_bank(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_bank.php",
      body
    );
  }

  update_vendor(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_vendor.php",
      body
    );
  }

  add_category(body) {
    // post these details to API server return user info   is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  del_cat(body) {
    // post these details to API server return user info   is correct
    return this.http.post<myData>(this.webapiurl + "/delete/del_cat.php", body);
  }

  add_item(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_item.php",
      body
    );
  }

  add_stock(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  add_deals(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  add_coupon(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  add_ticket(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/put_data/add_device.php",
      body
    );
  }

  get_vendor_list(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_vendors_list.php",
      body
    );
  }

  get_vendor_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_vendors_profile.php",
      body
    );
  }

  get_item_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_product_detail.php",
      body
    );
  }

  get_cat_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_cat_detail.php",
      body
    );
  }

  get_item_list(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_pro_list.php",
      body
    );
  }

  get_cat_list(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_catogery_list.php",
      body
    );
  }

  get_p_cat_list(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_p_catogery_list.php",
      body
    );
  }

  get_ven_item_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_ven_item_list.php",
      body
    );
  }

  upload_csv_data(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/add_csv_upload.php",
      body
    );
  }

  getOrderList(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_orders_list.php",
      body
    );
  }

  getorderData(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_ord_data.php",
      body
    );
  }

  getorderdetail(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_ord_info.php",
      body
    );
  }

  getordertotal(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/get_ord_total.php",
      body
    );
  }

  getshipapidata(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/getshipapidata.php",
      body
    );
  }

  getship_del_add(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/getship_del_add.php",
      body
    );
  }

  getship_bill_add(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/get_data/getship_bill_add.php",
      body
    );
  }

  updatestatus(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_status.php",
      body
    );
  }

  order_cancle_shipr(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/order_cancle_shipr.php",
      body
    );
  }

  gen_awb_shipr(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_awb.php",
      body
    );
  }

  pik_ord(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_pikup_req.php",
      body
    );
  }
  cancle_awb_order(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/cancle_awb_order.php",
      body
    );
  }

  update_item(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_item.php",
      body
    );
  }

  update_sequ(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_sequ.php",
      body
    );
  }

  add_tit(body) {
    // post these details to API server return user info is correct
    return this.http.post<myData>(
      this.webapiurl + "/update/update_fbar.php",
      body
    );
  }
}
